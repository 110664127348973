import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

var VisitaDominiciliariaSeguimiento = [
    {
       titulo: 'INFORMACIÓN PERSONAL',
       campos: [
            {
              label: 'FOTO DEL USUARIO',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false,
              justify: 'center'
            },
            {
             label: 'Nombres Completos',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Tipo de documento',
             tipo: 'desplegable',
             lista: 'documento',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'No documento',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Edad',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Género',
             tipo: 'desplegable',
             lista: ['Femenino', 'Masculino', 'Otro'],
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Estado civil',
             tipo: 'desplegable',
             lista: ['Casado', 'Soltero', 'Unión libre'],
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Teléfono Fijo',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'Celular',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'EPS',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'Fondo de Pensiones',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'ARL',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'C. compensación',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'Cesantías',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'Dirección',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'Cambios de información personal',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
           },
        ]
    },
    {
       titulo: 'HISTÓRICO DE VISITAS',
       campos: [
          {
             label: 'Visita Ingreso',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Visita anterior',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Visita actual',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Observación',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio:  false,
             wx: 3
          },
       ]
    },
    {
       titulo: 'CONCEPTO GENERAL DE LA VISITA',
       campos: [
           {
              label: '',
              tipo: 'ConceptoGeneralVisita',
              confiabilidad: {
                 label: 'Confiabilidad',
                 tipo: 'desplegable',
                 lista: ['Alta','Media','Baja'],
                 valor: null
              },
              riesgo: {
                 label: 'Riesgo',
                 tipo: 'desplegable',
                 lista: ['Alto','Medio','Bajo'],
                 valor: null
              },
              observacion: {
                 label: 'Observación',
                 tipo: 'textoLargo',
                 valor: null
              },
              ignorar: false
           }
       ]
    },
    {
       titulo: 'INFORMACIÓN FAMILIAR',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             ignorar: false,
             cabeceras: ['Parentesco', 'Nombre', 'Edad', 'Ocupación', 'Convivencia', 'Tiempo de convivencia'],
             listaValores: [],
             formato: [
                {label: 'Parentesco', tipo: 'texto', valor: null},
                {label: 'Nombre', tipo: 'texto', valor: null},
                {label: 'Edad', tipo: 'texto', valor: null},
                {label: 'Ocupación', tipo: 'texto', valor: null},
                {label: 'Convivencia', tipo: 'texto', valor: null},
                {label: 'Tiempo de convivencia', tipo: 'texto', valor: null}
             ],
             listaTotales: []
          },
          {
             label: 'Cambios en información familiar',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
          }
       ]
    },
    {
        titulo: 'INFORMACIÓN ACADÉMICA',
        campos: [
           {
              label: '',
              tipo:'duplicaCampos',
              campo: {
                 label: '',
                 tipo: 'form',
                 ignorar: false,
                 form: {
                    titulo: '',
                    campos: [
                       {label: 'Nivel educativo', tipo: 'texto', valor: null},
                       {label: 'Título obtenido', tipo: 'texto', valor: null},
                       {label: 'Modalidad', tipo: 'texto', valor: null},
                       {label: 'Institución', tipo: 'texto', valor: null},
                       {label: 'Ciudad', tipo: 'texto', valor: null},
                       {label: 'Año de graduación', tipo: 'texto', valor: null}
                    ]
                 }
              },
              listadoCampos: [],
              ignorar: false
           },
           {
               label: 'Cambios Percibidos',
               tipo: 'textoLargo',
               valor: null,
               ignorar: false,
               obligatorio:  false
            }
        ]
    },
    {
       titulo: 'ESTADO DE SALUD',
       campos: [
          {
             label: 'Visita anterior',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio:  false,
             wx: 2
          },
          {
             label: 'Visita actual',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio:  false,
             wx: 2
          },
          {
             label: 'Cambios relevantes',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio:  false,
             wx: 3
          }
       ]
    },
    {
       titulo: 'INFORMACIÓN VIVIENDA',
       campos: [
          {
             label: '',
             tipo: 'tablaComparativa',
             cabeceras: ['Visita Anterior', 'Visita Actual'],
             forms: [
                {
                   titulo: 'Clase de Vivienda',
                   campos1: [
                      {
                         label: 'Casas',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Apartamentos',
                         tipo: 'texto',
                         valor: null
                      }
                   ],
                   campos2: []
                },
                {
                   titulo: 'Tipo de vivienda',
                   campos1: [
                      {
                         label: 'Independiente',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Compartida',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Inquilinato',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Habitación',
                         tipo: 'texto',
                         valor: null
                      },
                   ],
                   campos2: []
                },
                {
                   titulo: 'Estado Vivienda',
                   campos1: [
                      {
                         label: 'Arrendada',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'propia',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Familiar',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Hipotecada',
                         tipo: 'texto',
                         valor: null
                      }
                   ],
                   campos2: []
                },
                {
                   titulo: 'Servicios',
                   campos1: [
                      {
                         label: 'Acueducto',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Energía',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Telefonía',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Internet',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Gas',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Cable',
                         tipo: 'texto',
                         valor: null
                      }
                   ],
                   campos2: []
                },
                {
                   titulo: 'Características',
                   campos1: [
                      {
                         label: 'Estrato No',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: '# Pisos',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: '# Baños',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: '# Habitaciones',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Cocina',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Área Social',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Terraza',
                         tipo: 'texto',
                         valor: null
                      },
                      {
                         label: 'Parqueadero',
                         tipo: 'texto',
                         valor: null
                      }
                   ],
                   campos2: []
                }
             ],
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Cambios Relevantes',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'EVALUACIÓN VIVIENDA',
       campos: [
          {
             label: 'Estado estructural de la vivienda',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Proporción hab. /No habitaciones',
             tipo: 'desplegable',
             valor: null,
             lista: ['Si', 'No'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Orden y aseo',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Electrodomésticos',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Servicios públicos',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Seguridad de la vivienda',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Mobiliario',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Cambios relevantes',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
          }
       ]
    },
    {
       titulo: 'EVALUACIÓN DEL SECTOR /BARRIO',
       campos: [
          {
             label: 'Vías de Acceso',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Medios de transporte',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Ubicación',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Percepción de Seguridad',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Zonas comunes y de recreación',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Centros de atención a emergencias',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Salubridad',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Cambios relevantes',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false
          }
       ]
    },
    {
        titulo: 'INFORMACIÓN FINANCIERA',
        campos: [
           {
              label: 'Número de personas que dependen económica de usted',
              tipo: 'numero',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Ingreso principal',
              tipo: 'numero',
              columnaSumatoria: 16,
              simbolo: '$',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Concepto',
              tipo: 'texto',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Aporte',
              tipo: 'numero',
              columnaSumatoria: 17,
              simbolo: '$',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Otros Ingresos',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: ['Ingreso', 'Concepto', 'Aporte'],
              listaValores: [],
              formato: [
                 {label: 'Ingreso', tipo: 'numero', sumatorio: true, columnaSumatoria: 16, valor: null, simbolo: '$'},
                 {label: 'Concepto', tipo: 'texto', valor: null},
                 {label: 'Aporte', tipo: 'numero', sumatorio: true, columnaSumatoria: 17, valor: null, simbolo: '$'}
              ],
              listaTotales: []
           },
           {
              label: 'Total Ingresos',
              tipo: 'numero',
              valor: null,
              simbolo: '$',
              columnaSumatoria: 30,
              resultadoSumatoria: 16,
              noEditable: true,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Total Aportes',
              tipo: 'numero',
              simbolo: '$',
              columnaSumatoria: 30,
              resultadoSumatoria: 17,
              noEditable: true,
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Observaciones o cambios relevantes',
              tipo: 'textoLargo',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Ingresos y aportes grupo familiar',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: ['Nombre', 'Parentesco', 'Ingreso', 'Aporte'],
              listaValores: [],
              formato: [
                 {label: 'Nombre', tipo: 'texto', valor: null},
                 {label: 'Parentesco', tipo: 'texto', valor: null},
                 {label: 'Ingreso', sumatorio: true, columnaSumatoria: 18, simbolo: '$', tipo: 'numero', valor: null},
                 {label: 'Aporte', sumatorio: true, columnaSumatoria: 19, simbolo: '$', tipo: 'numero', valor: null}
              ],
              listaTotales: []
           },
           {
              label: 'Total Ingresos',
              tipo: 'numero',
              simbolo: '$',
              resultadoSumatoria: 18,
              noEditable: true,
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Total Aportes',
              tipo: 'numero',
              simbolo: '$',
              resultadoSumatoria: 19,
              noEditable: true,
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Observaciones o cambios relevantes',
              tipo: 'textoLargo',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Propiedades inmuebles',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: ['Tipo', 'Dirección', 'Ciudad', 'Valor'],
              listaValores: [],
              formato: [
                 {label: 'Tipo', tipo: 'desplegable', lista: ['Casa','Apartamento','Lote','Local','Bodega','Parqueadero','Otro'], valor: null},
                 {label: 'Dirección', tipo: 'texto', valor: null},
                 {label: 'Ciudad', tipo: 'texto', valor: null},
                 {label: 'Valor', tipo: 'numero', sumatorio: true, columnaSumatoria: 20, valor: null, simbolo: '$'}
              ],
              listaTotales: []
           },
           {
              label: 'Total Valor Inmuebles',
              tipo: 'numero',
              simbolo: '$',
              valor: null,
              columnaSumatoria: 60,
              resultadoSumatoria: 20,
              noEditable: true,
              ignorar: false
           },
           {
              label: 'Propiedades vehículos',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: ['Tipo', 'Clase', 'Marca', 'Modelo', 'Placa', 'Valor'],
              listaValores: [],
              formato: [
                 {label: 'Tipo', tipo: 'desplegable', lista: ['Carro', 'Camioneta', 'Moto', 'Camión', 'Tracto mula', 'Otro'], valor: null},
                 {label: 'Clase', tipo: 'texto', valor: null},
                 {label: 'Marca', tipo: 'texto', valor: null},
                 {label: 'Modelo', tipo: 'texto', valor: null},
                 {label: 'Placa', tipo: 'texto', valor: null},
                 {label: 'Valor', tipo: 'numero', valor: null, sumatorio: true, columnaSumatoria: 21, simbolo: '$'}
              ]
           },
           {
              label: 'Total Propiedades Vehículos',
              tipo: 'numero',
              resultadoSumatoria: 21,
              columnaSumatoria: 60,
              simbolo: '$',
              noEditable: true,
              valor: null,
              ignorar: false
           },
           {
              label: 'Total Propiedades Patrimonio',
              tipo: 'numero',
              simbolo: '$',
              resultadoSumatoria: 60,
              noEditable: true,
              valor: null,
              ignorar: false
           },
           generCampos.darCampoTextoLargo('Observación o Cambios Relevantes',null,-1,false,false).toJSON()
        ]
     },
     {
        titulo: 'INFORMACIÓN CREDITICIA',
        campos: [
            {
               label: '',
               tipo: 'tabla',
               ignorar: false,
               cabeceras: ['Tipo de Obligación', 'Entidad', 'Valor Total Obligación', 'Cuota mensual', 'Tiempo en mora'],
               listaValores: [],
               formato: [
                  {label: 'Tipo de Obligación', tipo: 'texto', valor: null},
                  {label: 'Entidad', tipo: 'texto', valor: null},
                  {label: 'Valor Total Obligación', tipo: 'numero', columnaSumatoria: 65, simbolo: '$', valor: null},
                  {label: 'Cuota Mensual', tipo: 'numero', columnaSumatoria: 68, simbolo: '$', valor: null},
                  {label: 'Tiempo en Mora', tipo: 'texto', valor: null}
               ],
               listaTotales: []
            },
            {
               label: 'Total Obligación',
               tipo: 'numero',
               resultadoSumatoria: 65,
               simbolo: '$',
               valor: null,
               noEditable: true
            },
            {
               label: 'Total Cuota Mensual',
               tipo: 'numero',
               resultadoSumatoria: 68,
               valor: null,
               noEditable: true
            },
            {
               label: 'Total Tiempo de Mora',
               tipo: 'texto',
               valor: '',
            },
            {
               label: 'Observaciones o cambios relevantes',
               tipo: 'textoLargo',
               valor: null,
               ignorar: false,
               obligatorio: false
            }
        ]
     },
    {
       titulo: 'INFORMACIÓN BANCARIA',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             ignorar: false,
             cabeceras: ['Tipo de cuenta', 'Entidad', 'Ciudad'],
             listaValores: [],
             formato: [
                {label: 'Tipo de cuenta', tipo: 'texto', valor: null},
                {label: 'Entidad', tipo: 'texto', valor: null},
                {label: 'Ciudad', tipo: 'texto', valor: null}
             ],
             listaTotales: []
          },
          {
             label: 'Observaciones o cambios relevantes',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
          }
       ]
    },
    {
        titulo: 'ASPIRACIONES Y PROYECTOS',
        campos: [
           {
              label: '',
              tipo: 'tabla',
              cabeceras: [],
              formato:[],
              listaValores: [
                 [
                    {label:'', tipo: 'textoLargo', noEditable: true, valor: 'Personales'},
                    {label:'', tipo: 'textoLargo', valor: null},
                 ],
                 [
                    {label:'', tipo: 'textoLargo', noEditable: true, valor: 'Familiares'},
                    {label:'', tipo: 'textoLargo', valor: null},
                 ]
              ],
              columnasPeque: [0],
              noEditable: true,
              ignorar: false,
           }
        ]
     },
    {
       titulo: 'OCUPACIÓN TIEMPO LIBRE',
       campos: [
          {
             label: 'Práctica de deportes',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Hobbies o pasatiempos',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Gremios / asociaciones',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Hábitos de lectura',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Consumo de sustancias psicoactivas',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Consumo de bebidas alcohólicas',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Observación',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
          },
       ]
    },
    {
       titulo: 'ASPECTOS GENERALES DEL ENTREVISTADO',
       campos: [
         {
            label: 'Presentación Personal',
            tipo: 'desplegable',
            valor: null,
            lista: ['Excelente', 'Aceptable', 'Deficiente'],
            ignorar: false,
            obligatorio: false
         },
         {
            label: 'Información suministrada',
            tipo: 'desplegable',
            valor: null,
            lista: ['Excelente', 'Aceptable', 'Deficiente'],
            ignorar: false,
            obligatorio: false
         },
         {
            label: 'Expresión verbal',
            tipo: 'desplegable',
            valor: null,
            lista: ['Excelente', 'Aceptable', 'Deficiente'],
            ignorar: false,
            obligatorio: false
         },
         {
            label: 'Colaboración ',
            tipo: 'desplegable',
            valor: null,
            lista: ['Excelente', 'Aceptable', 'Deficiente'],
            ignorar: false,
            obligatorio: false
         },
         {
            label: 'Ascensos o reconocimiento en el último año ',
            tipo: 'textoLargo',
            valor: null,
            ignorar: false,
            obligatorio: false,
         },
         {
            label: 'Expectativas del cargo',
            tipo: 'textoLargo',
            valor: null,
            ignorar: false,
            obligatorio: false,
         },
         {
            label: 'Maneja recursos propios de la compañía (Títulos / Dinero / Valores / Otros)',
            tipo: 'textoLargo',
            valor: null,
            ignorar: false,
            obligatorio: false,
         },
         {
            label: 'Que valores son importantes en su núcleo familiar y como los promueve',
            tipo: 'textoLargo',
            valor: null,
            ignorar: false,
            obligatorio: false,
         },
         {
             label: 'Observación',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false
          }
       ]
    },
    {
       titulo: 'CONCEPTO FINAL DE LA VISITA',
       campos: [
          {
             label: 'CONCEPTO FINAL DE LA VISITA',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
              label: 'FIRMA',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false
           }
       ]
    },
    {
       titulo: 'DOCUMENTOS',
       campos: [
          {
            tipo: 'saltoPagina'
          },
          {
             label: 'AUTORIZACIÓN',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'CÉDULA',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'PASAPORTE',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'DIPLOMA BACHILLER',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'ICFES',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'ACTA DE GRADO BACHILLERATO',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'CERTIFICACIÓN LABORAL',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'OTROS SOPORTES',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'CONTRATO APRENDIZAJE',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
       ]
    }
]

export default(VisitaDominiciliariaSeguimiento)
