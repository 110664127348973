<template>
  <div :key="componentKey" v-if="valido">
    <FullBoxVue>
      <h1>Solicitud {{ $route.params.id }}</h1>
    </FullBoxVue>
    <FullBoxVue class="shadowHover"  v-if="
          $dataUser[0].rol == 'Admin' ||
          infoSolicitud.coordinador == $dataUser[0].id
        ">
      <MiddleBox

      >
        <h2>Seleccionar analista/proveedor</h2>
        <searchUserMultipleVue
          v-bind:people="userSearch"
          @selected="userSearchSelectedFunc"
        ></searchUserMultipleVue>
        <v-btn color="success" @click="actualizarEncargado()"
          >Actualizar analista/proveedor</v-btn
        >
        Encargado(s) actual(es): {{ infoSolicitud.encargados }}
      </MiddleBox>
      <MiddleBox
        v-if="
          $dataUser[0].rol == 'Admin' ||
          infoSolicitud.coordinador == $dataUser[0].id
        "
      >
        <h2>Cambiar estado solicitud</h2>
        <v-select
          v-model="estadoSolicitud"
          :items="estados"
          item-text="nombre"
          item-value="id"
          label="Estado"
        ></v-select>
        <v-btn color="success" @click="actualizarEstadoSolicitud()"
          >Actualizar Estado de solicitud</v-btn
        >
      </MiddleBox>
      <br /><br /><br />
      <FullBoxVue
        class="shadowHover"
        v-if="
          ($dataUser[0].rol == 'Admin' ||
            infoSolicitud.coordinador == $dataUser[0].id ||
            $dataUser[0].rol == 'Proveedor') &&
          infoSolicitud.estado != 'Finalizada' &&
          infoSolicitud.estado != 'Finalizada asesor' &&
          infoSolicitud.estado != 'Cancelada'
        "
      >
        <div style="display: block; width: 100%">
          <v-btn
            color="success"
            @click="
              realizarSolicitud.mostrarForm = !realizarSolicitud.mostrarForm
            "
            style="display: block; width: 100%"
            >Realizar informe</v-btn
          >
        </div>
      </FullBoxVue>
    </FullBoxVue>

    <FullBoxVue
      class="shadowHover"
      v-if="
        $dataUser[0].idRol == 3 || //admin
        $dataUser[0].idRol == 5 || //funcionario - analista
        $dataUser[0].idRol == 7 || //funcionario - coordinador
        $dataUser[0].idRol == 8 || //admin empresa
        $dataUser[0].idRol == 2 || //Cliente
        infoSolicitud.idEncargado == $dataUser[0].id ||
        infoSolicitud.encargado == $dataUser[0].id ||
        infoSolicitud.estado == 'Finalizada'
      "
    >
      <div
        v-if="
          ($dataUser[0].idRol == 3 ||
            infoSolicitud.encargado == $dataUser[0].id) &&
          infoSolicitud.estado != 'Finalizada' &&
          infoSolicitud.estado != 'Finalizada asesor' &&
          infoSolicitud.estado != 'Cancelada'
        "
      >
        <h2>Subir archivo nuevo</h2>
        <!-- <input type="file" @change="uploadFile" ref="file" accept="image/*" /> -->
        <input type="file" @change="uploadFile" ref="file" />
        &nbsp;
        <v-btn color="success" @click="subirArchivo()">Subir archivo</v-btn>
      </div>
      <br /><br />

      <v-container>
        <h2>Archivos subidos por evaluado</h2>
        <br /><br />
        <v-data-table
          :sort-by="['id']"
          :sort-desc="[true]"
          :headers="headers"
          :items="archivosSubidos"
        >
          <template v-slot:item.actions="{ item }">
            <div class="text-truncate">
              <v-icon
                small
                class="mr-2"
                color="primary"
                @click="descargarArchivosTable(item)"
              >
                mdi-download
              </v-icon>
              <v-icon
                small
                class="mr-2"
                color="error"
                v-if="
                  (item.idSubidoPor == $dataUser[0].id ||
                    $dataUser[0].idRol == 3) &&
                  infoSolicitud.estado != 'Finalizada' &&
                  infoSolicitud.estado != 'Finalizada asesor' &&
                  infoSolicitud.estado != 'Cancelada'
                "
                @click="eliminarArchivoTable(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-container>
    </FullBoxVue>

    <FullBoxVue>
      <MiddleBox class="shadowHover">
        <h2>Observaciones</h2>

        <div
          v-if="
            infoSolicitud.estado != 'Finalizada' &&
            infoSolicitud.estado != 'Finalizada asesor' &&
            infoSolicitud.estado != 'Cancelada'
          "
        >
          <v-textarea
            v-model="observacionesAnalistaProveedor"
            label="Observaciones"
          ></v-textarea>

          <v-btn
            color="success"
            @click="actualizarObservacionesAnalistaProveedor()"
            >Agregar observación</v-btn
          >
          <br /><br /><br />
        </div>
        <div
          class="chat-bubble"
          v-for="(object, index) in queryResultObservacionesAnalistaProveedor"
          :key="index"
        >
          <p class="message">{{ object.contenido }}</p>
          <span class="date">{{ object.subido }}</span>
          <button
            class="delete-btn"
            v-if="
              (object.subidoPor == $dataUser[0].id ||
                $dataUser[0].idRol == 3) &&
              infoSolicitud.estado != 'Finalizada' &&
              infoSolicitud.estado != 'Finalizada asesor' &&
              infoSolicitud.estado != 'Cancelada'
            "
            @click="eliminarObservacion(object.id)"
          >
            Eliminar
          </button>
        </div>
      </MiddleBox>

      <MiddleBox class="shadowHover">
        <h2>Costos adicionales</h2>

        <div
          v-if="
          ($dataUser[0].idRol == 3 || //admin
        $dataUser[0].idRol == 5 || //funcionario - analista
        $dataUser[0].idRol == 7) &&
            infoSolicitud.estado != 'Finalizada' &&
            infoSolicitud.estado != 'Finalizada asesor' &&
            infoSolicitud.estado != 'Cancelada'
          "
        >
          <v-text-field
            v-model="costoAdicionalDescripcion"
            label="Descripción del costo adicional"
          ></v-text-field>
          <v-text-field
            v-model="costoAdicionalValor"
            label="Valor costo adicional"
            type="number"
          ></v-text-field>
          <v-btn color="success" @click="actualizarCostoAdicional()"
            >Agregar costo adicional</v-btn
          >
          <br /><br /><br />
        </div>
        <div
          class="chat-bubble"
          v-for="(object, index) in queryCostosAdicionalesVal"
          :key="index"
        >
          <p class="message">{{ object.contenido }}</p>
          <p class="message">
            <b>${{ object.costo }}</b>
          </p>
          <span class="date">{{ object.subido }}</span>
          <button
            class="delete-btn"
            v-if="
              (object.subidoPor == $dataUser[0].id ||
                $dataUser[0].idRol == 3) &&
              infoSolicitud.estado != 'Finalizada' &&
              infoSolicitud.estado != 'Finalizada asesor' &&
              infoSolicitud.estado != 'Cancelada'
            "
            @click="eliminarCostoAdicional(object.id)"
          >
            Eliminar
          </button>
        </div>
      </MiddleBox>
    </FullBoxVue>

    <MiddleBox class="shadowHover">
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="infoSolicitud.id"
                label="Id"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="infoSolicitud.CentroCostos"
                label="Centro de costos"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                :value="infoSolicitud.NOMBRE_SERVICIO"
                label="Nombre servicio"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                :value="infoSolicitud.NOMBRE_SUBSERVICIO"
                label="Nombre subservicio"
                readonly
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                :value="infoSolicitud.NOMBRE_CIUDAD"
                label="Nombre ciudad"
                readonly
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                :value="infoSolicitud.NOMBRE_DEPARTAMENTO"
                label="Nombre departamento"
                readonly
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                :value="infoSolicitud.nombreCliente"
                label="Nombre cliente"
                readonly
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                :value="infoSolicitud.nombreEmpresa"
                label="Nombre empresa"
                readonly
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                :value="infoSolicitud.estado"
                label="Estado de la solicitud"
                readonly
                filled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </MiddleBox>

    <MiddleBox class="shadowHover">
      <h2>Candidato(s)</h2>
      <v-form
        v-for="(candidato, index) in infoSolicitudCandidatos"
        :key="index"
      >
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="candidato.nombreCandidato"
                label="Nombre candidato"
                readonly
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                v-model="candidato.cargo"
                label="Cargo"
                readonly
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                v-model="candidato.tipo_doc"
                label="Tipo documento"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="candidato.num_doc"
                label="Número de documento"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="candidato.numTelefonico"
                label="Número telefónico"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="candidato.correo"
                label="Correo"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="candidato.direccion"
                label="Dirección"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="candidato.observaciones"
                label="Observaciones"
                readonly
                filled
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              v-if="candidato.archivo != '' && candidato.archivo != null"
            >
              <v-btn
                color="success"
                @click="
                  descargarArchivos(candidato.archivo, candidato.nombreArchivo)
                "
                >Descargar archivo adjunto</v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" v-else>
              Este candidato no cuenta con archivos
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </MiddleBox>
    <v-dialog
      v-model="realizarSolicitud.mostrarForm"
      scrollable
      class="no-scroll"
      style=""
    >
      <CreateManyForm
        :forms="dataInformes"
        :idSolicitud="$route.params.id"
        :idForm="realizarSolicitud.idForm"
        v-if="!cargandoForm"
        @update:cambiarScroll="actualizarScroll"
      >
        <template slot="botones">
          <v-switch
            class="px-12"
            label="Mostrar Logo Colpryst"
            v-model="realizarSolicitud.mostrarLogoColpryst"
          ></v-switch>
          <v-btn class="green" @click="guardarInforme()"
            ><v-icon>mdi-content-save</v-icon></v-btn
          >
          <v-btn class="blue" @click="realizarSolicitud.mostrarForm = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </template>
        <template slot="titulo">{{
          realizarSolicitud.tituloFormulario
        }}</template>
        <template slot="ultimaPagina">
          <v-dialog inset>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="previsualizarFormulario()"
                color="primary"
              >
                Previsualizar informe
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar dark color="primary">
                  <v-btn icon dark @click="dialog.value = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Previsualizar Informe</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <div v-html="previewFormulario"></div>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>
          <br /><br />
          <v-btn class="green white--text" @click="enviarFormulario()"
            >Generar Informe</v-btn
          >
        </template>
      </CreateManyForm>
      <div ref="cardContainer" v-else class="dialog">
        <v-card class="vCard">
          <br />
          <loadingCSS>
            Generando informe, por favor espere un momento...
          </loadingCSS>
          <br />
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import MiddleBox from "@/components/static/MiddleBox.vue";
import searchUserMultipleVue from "../components/searchUserMultiple.vue";
import CreateManyForm from "@/components/CreateManyForm.vue";
import loadingCSS from "@/components/static/loadingCSS.vue";
import {
  getPeticion,
  getCandidatos,
  downloadFile,
  getArchivos,
  subirArchivos,
  eliminarArchivos,
  getFunProv,
  updateEncargadoSolicitud,
  getEstadosPeticiones,
  updateEstadoSolicitud,
  updateObservacionesAnalistaProveedor,
  getObservacionesAnalista,
  eliminarObservacion,
  updateCostoAdicional,
  eliminarCostoAdicional,
  getCostosAdicionales,
  generarInformeSolicitud,
  guardarInformeTemporal,
} from "@/api";
import formularios from "@/forms/formularios.js";
import CircularJSON from "circular-json";
import html2pdf from "html2pdf.js";

export default {
  mounted() {
    this.actualizarScroll(scrollPosition);
  },
  data() {
    return {
      realizarSolicitud: {
        idForm: "",
        tituloFormulario: "",
        mostrarForm: false,
        scrolly: 0,
        mostrarLogoColpryst: true,
      },
      infoSolicitud: {},
      infoSolicitudCandidatos: {},
      componentKey: 1,
      estadoSolicitud: null,
      estados: null,
      idSolicitud: this.$route.params.id,
      archivosSubidos: [],
      Images: null,
      valido: false,
      userSearchSelected: [],
      userSearch: [],
      observacionesAnalistaProveedor: "",
      queryResultObservacionesAnalistaProveedor: [],
      queryCostosAdicionalesVal: [],
      costoAdicionalDescripcion: "",
      costoAdicionalValor: "",
      cargandoForm: false,
      headers: [
        { text: "Acciones", value: "actions", sortable: false },
        { text: "Id", value: "id" },
        { text: "Nombre de archivo", value: "nombreArchivo" },
        { text: "Fecha de subida", value: "creado" },
        { text: "subidoPor", value: "subidoPor" },
        { text: "Observaciones", value: "comentarios" },
        { text: "Archivo requerido", value: "observaciones" },
      ],
      dataInformes: {},
      previewFormulario: "",
      unmounted: null,
    };
  },

  components: {
    FullBoxVue,
    MiddleBox,
    searchUserMultipleVue,
    CreateManyForm,
    loadingCSS,
  },

  methods: {
    eliminarArchivoTable(data) {
      this.eliminarArchivo(data.id);
    },

    descargarArchivosTable(data) {
      this.descargarArchivos(data.archivo, data.nombreArchivo);
    },

    actualizarEncargado() {
      let bodySol = {
        idEncargado: this.userSearchSelected,
        idSolicitud: this.idSolicitud,
      };
      updateEncargadoSolicitud(bodySol).then(
        function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Solicitud enviada exitosamente",
          });
        }.bind(this)
      );
    },

    queryObservacionesAnalistaProveedor() {
      getObservacionesAnalista(this.idSolicitud).then(
        function (response) {
          this.queryResultObservacionesAnalistaProveedor = response.data;
        }.bind(this)
      );
    },

    queryCostosAdicionales() {
      getCostosAdicionales(this.idSolicitud).then(
        function (response) {
          this.queryCostosAdicionalesVal = response.data;
        }.bind(this)
      );
    },

    eliminarObservacion(id) {
      eliminarObservacion(id).then(
        function (response) {
          this.queryObservacionesAnalistaProveedor();
        }.bind(this)
      );
    },

    eliminarCostoAdicional(id) {
      eliminarCostoAdicional(id).then(
        function (response) {
          this.queryCostosAdicionales();
        }.bind(this)
      );
    },

    actualizarCostoAdicional() {
      let bodySol = {
        costoAdicionalDescripcion: this.costoAdicionalDescripcion,
        costoAdicionalValor: this.costoAdicionalValor,
        idSolicitud: this.idSolicitud,
      };

      updateCostoAdicional(bodySol).then(
        function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Solicitud enviada exitosamente",
          });
          this.costoAdicionalValor = "";
          this.costoAdicionalDescripcion = "";
          this.queryCostosAdicionales();
        }.bind(this)
      );
    },

    actualizarObservacionesAnalistaProveedor() {
      let bodySol = {
        observacionesAnalistaProveedor: this.observacionesAnalistaProveedor,
        idSolicitud: this.idSolicitud,
      };
      updateObservacionesAnalistaProveedor(bodySol).then(
        function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Solicitud enviada exitosamente",
          });
          this.observacionesAnalistaProveedor = "";
          this.queryObservacionesAnalistaProveedor();
        }.bind(this)
      );
    },

    actualizarEstadoSolicitud() {
      let bodySol = {
        idEstado: this.estadoSolicitud,
        idSolicitud: this.idSolicitud,
      };
      updateEstadoSolicitud(bodySol).then(
        function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Solicitud enviada exitosamente",
          });
          this.realizarConsultaCandidatos();
          this.queryObservacionesAnalistaProveedor();
          this.queryCostosAdicionales();
        }.bind(this)
      );
    },

    userSearchSelectedFunc(values) {
      this.userSearchSelected = values;
    },

    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },

    eliminarArchivo(id) {
      eliminarArchivos(id).then(
        function (response) {
          this.realizarConsultaArchivos();
        }.bind(this)
      );
    },

    ajustarValoresCampos(dataForm) {
      for (let i in dataForm) {
        if (
          dataForm[i].label.toUpperCase() == "NOMBRES" ||
          dataForm[i].label.toUpperCase() == "NOMBRES Y APELLIDOS" ||
          dataForm[i].label.toUpperCase() == "NOMBRES COMPLETOS"
        ) {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].nombreCandidato;
        }
        if (dataForm[i].label.toUpperCase() == "NÚMERO DE CÉDULA") {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].num_doc;
        }
        if (dataForm[i].label.toUpperCase() == "NO DE DOCUMENTO") {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].num_doc;
        }

        if (dataForm[i].label.toUpperCase() == "TIPO DE DOCUMENTO") {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].tipo_doc_abr;
        }
        if (dataForm[i].label.toUpperCase() == "NO DOCUMENTO") {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].num_doc;
        }
        if (
          dataForm[i].label.toUpperCase() == "TELÉFONO" ||
          dataForm[i].label.toUpperCase() == "TELEFONO"
        ) {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].numTelefonico;
        }
        if (
          dataForm[i].label.toUpperCase() == "DIRECCIÓN" ||
          dataForm[i].label.toUpperCase() == "DIRECCION"
        ) {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].direccion;
        }
        if (dataForm[i].label.toUpperCase() == "CELULAR") {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].numTelefonico;
        }
        if (dataForm[i].label.toUpperCase() == "DIRECCIÓN RESIDENCIAL") {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].direccion;
        }
        if (dataForm[i].label.toUpperCase() == "CARGO AL QUE ASPIRA") {
          dataForm[i].valor = this.infoSolicitudCandidatos[0].cargo;
        }
        if (dataForm[i].label.toUpperCase() == "COMPAÑÍA SOLICITANTE") {
          dataForm[i].valor = this.infoSolicitud.nombreEmpresa;
        }
      }
    },

    realizarConsulta() {
      getPeticion(this.idSolicitud).then(
        function (response) {
          this.infoSolicitud = response.data;
          this.observacionesAnalistaProveedor = response.data.observaciones;
          this.estadoSolicitud = this.infoSolicitud.idEstado;
          this.componentKey += 1;

          let newInform = JSON.parse(response.data.Informe.json);

          //Asignación de valores en caso de que el formulario sea nuevo
          if (response.data.nuevoForm) {
            let dataForm = newInform[0].campos;
            this.ajustarValoresCampos(dataForm);
          }
          response.data.Informe.json = JSON.stringify(newInform);

          this.darFormularios(response.data.Informe);

          getFunProv(this.infoSolicitud.ID_SERVICIO).then(
            function (response) {
              let userSearch = [];
              for (let user in response.data) {
                userSearch.push({
                  id: response.data[user].id,
                  nombre:
                    response.data[user].nombre +
                    " - " +
                    response.data[user].solicitudesAsignadas +
                    " solicitudes asignadas",
                });
              }
              this.userSearch = userSearch;
            }.bind(this)
          );
        }.bind(this)
      );
    },

    realizarConsultaEstados() {
      getEstadosPeticiones().then(
        function (response) {
          this.estados = response.data;
        }.bind(this)
      );
    },

    realizarConsultaCandidatos() {
      getCandidatos(this.idSolicitud).then(
        function (response) {
          this.infoSolicitudCandidatos = response.data;
          this.componentKey += 1;
          this.realizarConsulta();
        }.bind(this)
      );
    },

    realizarConsultaArchivos() {
      getArchivos(this.idSolicitud).then(
        function (response) {
          //Haz un código donde el response.data elimine los archivos que tengan en response.data.oberservaciones un texto igual a FIRMA
          response.data = response.data.filter(file => file.observaciones !== 'FIRMA');

          this.archivosSubidos = response.data;
        }.bind(this)
      );
    },

    subirArchivo() {
      const formData = new FormData();
      formData.append("file", this.Images);
      formData.append("idSolicitud", this.idSolicitud);

      subirArchivos(formData).then(
        function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Solicitud enviada exitosamente",
          });
          this.realizarConsultaArchivos();
        }.bind(this)
      );
    },

    descargarArchivos(filepath, fileName) {
      downloadFile(filepath).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
    },

    darFormularios(dataInforme) {

      this.realizarSolicitud.tituloFormulario = dataInforme.nombreFormulario;
      this.realizarSolicitud.idForm = dataInforme.codigo;
      this.realizarSolicitud.vigencia = dataInforme.vigencia;
      if (formularios.modoPrueba) {
        this.dataInformes = formularios.formularioPrueba;
        let dataForm = this.dataInformes[0].campos;
        this.ajustarValoresCampos(dataForm);
        if (formularios.copiarFormulario) {

          var result = CircularJSON.stringify(formularios.formularioPrueba);
          navigator.clipboard.writeText( result.toString());
          alert("Copiado formulario");
        }
      } else {
        this.dataInformes = JSON.parse(dataInforme.json);
      }
    },

    guardarInforme() {
      if(this.$route.params.id == null){
        this.dataInformes == null;
        return;
      }

      const bodyRequest = {
        tituloFormulario: this.realizarSolicitud.tituloFormulario,
        idForm: this.realizarSolicitud.idForm,
        vigencia: this.realizarSolicitud.vigencia,
        mostrarLogoColpryst: this.realizarSolicitud.mostrarLogoColpryst,
        idSolicitud: this.$route.params.id,
        data: CircularJSON.stringify(this.dataInformes),
        infoSolicitud: {
          nombreEmpresa: this.infoSolicitud.nombreEmpresa,
          nombreCliente: this.infoSolicitud.nombreCliente,
        },
      };

      guardarInformeTemporal(bodyRequest).then(
        function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Informe guardado correctamente.",
          });
        }.bind(this)
      );
    },

    previsualizarFormulario() {
      const bodyRequest = {
        tituloFormulario: this.realizarSolicitud.tituloFormulario,
        idForm: this.realizarSolicitud.idForm,
        vigencia: this.realizarSolicitud.vigencia,
        idSolicitud: this.$route.params.id,
        mostrarLogoColpryst: this.realizarSolicitud.mostrarLogoColpryst,
        data: this.dataInformes,
        infoSolicitud: {
          nombreEmpresa: this.infoSolicitud.nombreEmpresa,
          nombreCliente: this.infoSolicitud.nombreCliente,
        },
        mostrarPrevisualizacion: true,
      };

      generarInformeSolicitud(bodyRequest).then((response) => {
        this.previewFormulario = response.data;
      });
    },

    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    },

    enviarFormulario() {
      this.cargandoForm = true;
      this.guardarInforme();
      const bodyRequest = {
        tituloFormulario: this.realizarSolicitud.tituloFormulario,
        idForm: this.realizarSolicitud.idForm,
        vigencia: this.realizarSolicitud.vigencia,
        mostrarLogoColpryst: this.realizarSolicitud.mostrarLogoColpryst,
        idSolicitud: this.$route.params.id,
        data: this.dataInformes,
        infoSolicitud: {
          nombreEmpresa: this.infoSolicitud.nombreEmpresa,
          nombreCliente: this.infoSolicitud.nombreCliente,
        },
        mostrarPrevisualizacion: false,
      };

      generarInformeSolicitud(bodyRequest).then((response) => {
        const fechaActual = new Date();
        const dia = String(fechaActual.getDate()).padStart(2, "0");
        const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11, por eso se suma 1
        const anio = fechaActual.getFullYear();
        const nombreArchivo = `Informe_${dia}-${mes}-${anio}.pdf`;

        const linkSource = `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = nombreArchivo;
        downloadLink.click();

        const file = this.DataURIToBlob(linkSource);
        const formData = new FormData();
        formData.append("file", file, nombreArchivo);
        formData.append("idSolicitud", this.idSolicitud);

        subirArchivos(formData).then(
          function (response) {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: "Solicitud enviada exitosamente",
            });
            this.realizarConsultaArchivos();
            this.cargandoForm = false;
          }.bind(this)
        );
      });
    },
    actualizarScroll(scrollPosition) {
      const ventanaFormulario = this.$refs.ventanaFormulario;
      ventanaFormulario.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    },

    jobGuardarInforme(){
      if(this.realizarSolicitud.mostrarForm){
        this.guardarInforme();
      }
    }
  },

  mounted() {
    this.realizarConsultaCandidatos();
    this.queryObservacionesAnalistaProveedor();
    this.queryCostosAdicionales();
    this.interval =  setInterval(() => { this.jobGuardarInforme() }, 60000);
  },

  unmounted(){
    console.log("unmounted");
    clearInterval(this.interval)
  },

  watch: {
    infoSolicitud(newState) {
      if (newState != {}) {
        this.valido = true;
        this.realizarConsultaArchivos();
        this.realizarConsultaEstados();
      }
    },
  },
};
</script>

<style>
.archivo {
  min-width: 300px;
  max-width: 300px;
  min-height: 170px;
  padding: 10px;
}

.archivo:hover {
  background: #385da6;
  color: #fff;
}

.archivo:hover > div > i {
  color: #fff;
}
.archivo:hover > div {
  cursor: pointer;
}

.archivo:hover > div > div > div label {
  color: #fff;
}

.archivo:hover > div > div > div > textarea {
  color: #fff;
}

.chat-bubble {
  background-color: #f1f0f0;
  border-radius: 15px;
  padding: 10px;
  width: 80%;
  display: inline-block;
  margin: 10px 0;
}

.message {
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
}

.date {
  font-size: 12px;
  color: #666;
  display: block;
  margin-top: 5px;
}

.delete-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
}
.no-scroll .v-dialog__content {
  overflow: hidden !important;
}
.no-scroll .v-dialog__content::-webkit-scrollbar {
  display: none;
}

.no-scroll .v-dialog__content {
  scrollbar-width: none;
}
</style>
