import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

var VisitaAsociadosNegocios = [
    {
       titulo: 'DATOS DEL ASOCIADO DE NEGOCIOS',
       campos: [
          {
             label: 'Fecha Visita',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'Razón Social',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Nit',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Teléfono',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Email',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Ciudad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Dirección',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Tipo de Operación',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Tiempo de Permanencia en la Instalación',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Cliente',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Proveedor',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Tipo de Operación',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Vinculación',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Actualización',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Instalaciones Propias',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Nombre Arrendador',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Otro',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
       ]
    },
    {
       titulo: 'INFORMACIÓN GENERAL',
       campos: [
          {
             label: '',
             tipo:  'tabla',
             cabeceras: ['REQUISITO','RTA','OBSERVACIÓN O DESCRIPCIÓN'],
             formato: [],
             listaValores: [
                [
                   {label: '', tipo: 'textoLargo', noEditable: true, valor: 'La dirección concuerda con la registrada en el RUT'},
                   {label: '', tipo: 'desplegable', lista: ['Si', 'No'], valor: null},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', noEditable: true, valor: 'Las instalaciones de la empresa ofrecen seguridad respecto al fácil acceso de intrusos'},
                   {label: '', tipo: 'desplegable', lista: ['Si', 'No'], valor: null},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', noEditable: true, valor: 'Se evidencia aviso visible en fachada de la empresa conforme a su razón social'},
                   {label: '', tipo: 'desplegable', lista: ['Si', 'No'], valor: null},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', noEditable: true, valor: 'Los equipos, maquinaria, insumos y demás elementos evidenciados coinciden con la línea de negocio realizada'},
                   {label: '', tipo: 'desplegable', lista: ['Si', 'No'], valor: null},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', noEditable: true, valor: 'Se realizan actividades por parte del Cliente fuera del objeto social establecido en certificado de existencia y representación legal'},
                   {label: '', tipo: 'desplegable', lista: ['Si', 'No'], valor: null},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
             ],
             columnasPeque: [1],
             noEditable: true,
             ignorar: false,
          },
          {
             label: '',
             tipo: 'tabla',
             cabeceras: [],
             formato: [],
             listaValores: [
                [
                   {label: '', tipo: 'textoLargo', valor: 'DESCRIPCION DE LA ACTIVIDAD', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor: 'DESCRIPCION DEL ASOCIADO (Año de creación, clientes, No de colaboradores, y sedes)', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor: 'DESCRIPCION DE LA INTALACIONES', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
             ],
             columnasPeque: [0],
             noEditable: true,
             ignorar: false,
          }
       ]
    },
    {
       titulo: 'REFERENCIAS COMERCIALES',
       campos: [
          {
             label: 'Nombre de la Empresa',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Tipo de Documento',
             tipo: 'desplegable',
             lista: 'documento',
             valor: null,
             ignorar: false
          },
          {
             label: 'NO Documento',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Dirección',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Contacto',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Cargo',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Teléfono',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Tiempo de Relación',
             tipo: 'texto',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'DOCUMENTOS',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['REQUISITO','RESPUESTA','OBSERVACIÓN O DESCRIPCIÓN'],
             formato: [],
             columnasPeque: [0,1],
             listaValores: [
                [
                   {label: '', tipo: 'texto', valor: 'Cámara de Comercio', noEditable: true},
                   {label: '', tipo: 'desplegable', lista: ['Si', 'No'], valor: null},
                   {label: '', tipo: 'texto', valor: null},
                ],
                [
                   {label: '', tipo: 'texto', valor: 'Rut', noEditable: true},
                   {label: '', tipo: 'desplegable', lista: ['Si', 'No'], valor: null},
                   {label: '', tipo: 'texto', valor: null},
                ],
                [
                   {label: '', tipo: 'texto', valor: 'Cédula Representante Legal', noEditable: true},
                   {label: '', tipo: 'desplegable', lista: ['Si', 'No'], valor: null},
                   {label: '', tipo: 'texto', valor: null},
                ],
                [
                   {label: '', tipo: 'texto', valor: 'Certificación Bancaria', noEditable: true},
                   {label: '', tipo: 'desplegable', lista: ['Si', 'No'], valor: null},
                   {label: '', tipo: 'texto', valor: null},
                ],
             ],
             listaTotales: [],
             noEditable:  true,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'CONCEPTO FINAL',
       campos: [
          {
             label: 'CONCEPTO FINAL',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'INFORMACIÓN DE QUIEN RECIBE LA VISITA',
       campos: [
          {
             label: 'Nombre y Apellidos',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Cargo',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Teléfono',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Correo',
             tipo: 'texto',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'REGISTRO FOTOGRÁFICO',
       campos: [
           {
             tipo: 'saltoPagina'
           },
          {
             label: 'Foto 1',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false,
             ancho: '49%'
          },
          {
             label: 'Foto 2',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false,
             ancho: '49%'
          },
          {
             label: 'Foto 3',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false,
             ancho: '49%'
          },
          {
             label: 'Foto 4',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false,
             ancho: '49%'
          }
       ]
    },
    {
       titulo: 'INFORMACIÓN DE QUIEN REALIZA LA VISITA',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['Nombre y Apellido', 'Cargo', 'Firma quien realiza la visita'],
             formato: [
               {label: '', tipo: 'texto', valor: null},
               {label: '', tipo: 'texto', valor: null},
               {
                  label: 'Firma Quien Realiza Visita',
                  tipo: 'archivo',
                  permitido: 'image/png, image/jpeg',
                  valor: null,
                  ignorar: false,
                  obligatorio: false,
                  ancho: '49%'
               },
             ],
             listaValores: [],
             ignorar: false
          }
       ]
    }
   ]
export default(VisitaAsociadosNegocios)
