import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

const ID_CEDULA_USUARIO = 4
const ID_TIPO_CEDULA = 3
const ID_NOMBRES_APELLIDOS = 6

var informeConsultas = [
    {
       titulo: 'INFORMACIÓN PERSONAL',
       campos: [
         generCampos.darCampoTexto('Nombres Completos',null,ID_NOMBRES_APELLIDOS,false,false).toJSON(),
         generCampos.darCampoDesplegable('Tipo de Documento',null,ID_TIPO_CEDULA,'documento',false).toJSON(),
         generCampos.darCampoTexto('No de Documento',null,ID_CEDULA_USUARIO,false,false).toJSON(),
       ]
    },
    {
       titulo: 'CONCEPTO GENERAL',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['','',''],
             formato: [],
             filasCondicionales: true,
             columnaDependiente: 2,
             mostrarFila: 'Aplica',
             noMostrarFila: 'No aplica',
             listaValores: [
                [
                   {label: '', tipo: 'texto', valor: 'Consulta Bases Publicas', noEditable: true},
                   {label: '', tipo: 'desplegable', lista: ['Aplica', 'No aplica'], valor: null},
                   {label: '', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
                ],
                [
                   {label: '', tipo: 'texto', valor: 'Consulta Bases privadas', noEditable: true},
                   {label: '', tipo: 'desplegable', lista: ['Aplica', 'No aplica'], valor: null},
                   {label: '', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
                ],
                [
                   {label: '', tipo: 'texto', valor: 'Información Financiera', noEditable: true},
                   {label: '', tipo: 'desplegable', lista: ['Aplica', 'No aplica'], valor: null},
                   {label: '', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
                ],
                [
                   {label:'', tipo: 'texto', valor: 'Ubica', noEditable: true},
                   {label:'', tipo: 'desplegable', lista: ['Aplica', 'No aplica'], valor: null},
                   {label:'', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
                ],
                [
                   {label: '', tipo: 'texto', valor: 'Bienes', noEditable: true},
                   {label:'', tipo: 'desplegable', lista: ['Aplica', 'No aplica'], valor: null},
                   {label:'', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
                ],
                [
                   {label:'', tipo: 'texto', valor: 'Propiedades', noEditable: true},
                   {label:'', tipo: 'desplegable', lista: ['Aplica', 'No aplica'], valor: null},
                   {label: '', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
                ],
             ],
             listaTotales: [],
             noEditable: true,
             ignorar: false
          },
          {
             label: 'CONCEPTO GENERAL ASESOR',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'CONSULTAS BASES',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['TIPO DE CONSULTA', 'VERIFICACIÓN'],
             formato: [],
             listaValores: [
                [
                   {label: '', tipo: 'texto', valor: null},
                   {label: '', tipo: 'texto', valor: null},
                ],
                [
                   {label: '', tipo: 'texto', valor: null},
                   {label: '', tipo: 'texto', valor: null},
                ]
             ],
             listaTotales: [],
             ignorar: false,
             noEditable: true
          },
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: 'Consultadas las bases de datos públicas y privadas, se confirma que el señor (a)',
                   colorLetra: 'red'
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      noEditable: true,
                      idDependiente: 6,
                      tipo: 'texto',
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: 'con cédula de ciudadanía número '
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'texto',
                      noEditable: true,
                      idDependiente: 4,
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: ', se considera con un riesgo '
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'texto',
                      valor: null
                   }
                }
             ],
             ignorar: false
          },
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Debido a lo sensible de la información entregada en estos requerimientos se recuerda
                   que todas las anotaciones de los antecedentes de tipo judicial están protegidas por la reserva legal.
                    Es deber de toda autoridad asegurar la confidencialidad de la misma, de conformidad con el art.
                    294, 418, 419, 463 del Código Penal (Ley 599/2000), art 149 y 150, Código Penal Militar (Ley 522/99);
                     por lo tanto, no deben ser de conocimiento de las personas relacionadas en este requerimiento.
                     Es importante tener en cuenta que el reporte no sustituye la decisión judicial, por tal motivo la
                     información debe ser verificada con la autoridad judicial correspondiente que aparece en el mismo,
                     esto para descartar posibles homónimos, el delito por el cual se adelanta el proceso y el estado del mismo.
                     Es de anotar y tal como lo estipula la normatividad vigente, la información relacionada con anotaciones
                     (medidas de aseguramiento y órdenes de captura), está sujeta a la reserva legal, la violación o desconocimiento
                      a esta reserva genera para el funcionario que las viole consecuencias de orden penal,
                      disciplinario y hasta patrimonial (acción de repetición).`,
                   colorLetra: 'red'
                }
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'ANÁLISIS FINANCIERO',
       campos: [
          {
             label: 'INFORMACIÓN CUENTAS BANCARIAS',
             tipo: 'tabla',
             cabeceras: ['Estado Cuenta', 'Tipo Cuenta', 'Entidad', 'Fecha apertura', 'No. cuenta'],
             listaValores: [],
             formato: [
                {label: 'Estado Cuenta', tipo: 'texto', valor: null},
                {label: 'Tipo Cuenta', tipo: 'texto', valor: null},
                {label: 'Entidad', tipo: 'texto', valor: null},
                {label: 'Fecha apertura', tipo: 'fecha', valor: null},
                {label: 'No. cuenta', tipo: 'texto', valor: null},
             ],
             ignorar: false
          },
          {
             label: 'INFORMACIÓN OBLIGACIONES VIGENTES',
             tipo: 'tabla',
             cabeceras: ['Tipo de Obligación', 'Entidad', 'Valor Inicial', 'Valor Utilizado', 'Valor cuota', 'Valor Mora', 'N cuotas pendientes'],
             listaValores: [],
             formato: [
                {label: 'Tipo de Obligación', tipo: 'texto', valor: null},
                {label: 'Entidad', tipo: 'texto', valor: null},
                generCampos.darCampoNumero('Valor Inicial',null,null,'$',false,false).toJSON(),
                generCampos.darCampoNumero('Valor Utilizado',null,null,'$',false,false).toJSON(),
                generCampos.darCampoNumero('Valor cuota',null,null,'$',false,false).toJSON(),
                generCampos.darCampoNumero('Valor Mora',null,null,'$',false,false).toJSON(),
                generCampos.darCampoTexto('N cuotas pendientes',0,null,false,false).toJSON(),
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'INFORMACIÓN ÚBICA',
       campos: [
           {
             tipo: 'saltoPagina'
           },
          {
             label: 'SOPORTE CONSULTA',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          }
       ]
    },
    {
       titulo: 'ÚLTIMOS DATOS HISTÓRICOS DE DIRECCIONES',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['Tipo', 'Direcciones', 'Ciudad', 'Primer Reporte', 'Último Reporte', 'Activo', 'N. Reportes'],
             listaValores: [],
             formato: [
                {label: 'Tipo', tipo: 'texto', valor: null},
                {label: 'Direcciones', tipo: 'texto', valor: null},
                {label: 'Ciudad', tipo: 'texto', valor: null},
                {label: 'Primer Reporte', tipo: 'texto', valor: null},
                {label: 'Último Reporte', tipo: 'texto', valor: null},
                {label: 'Activo', tipo: 'texto', valor: null},
                {label: 'N. Reportes', tipo: 'texto', valor: null}
             ],
             ignorar: false
          },
          generCampos.darCampoCompletarTexto('',[
            'la persona consultada cuenta con ',
            generCampos.darCampoTexto('',null,null,false,false).toJSON(),
            'registros de tipo ',
            generCampos.darCampoDesplegable('',null,null,['RESIDENCIAL','LABORAL'],false).toJSON(),
            ', donde ',
            generCampos.darCampoTexto('',null,-1,false,false).toJSON(),
            'se encuentra ',
            generCampos.darCampoTexto('',null,-1,false,false).toJSON()
          ]).toJSON()
       ]
    },
    {
       titulo: 'ÚLTIMOS DATOS HISTÓRICOS NUMERO TELEFÓNICOS',
       campos: [
         generCampos.darCampoTabla('',
            ['Celular', 'Primer reporte', 'Último reporte', 'Activo', 'No de reportes'],
            [
               {label: 'Celular', tipo: 'texto', valor: null},
               {label: 'Primer reporte', tipo: 'texto', valor: null},
               {label: 'Último reporte', tipo: 'texto', valor: null},
               {label: 'Activo', tipo: 'texto', valor: null},
               {label: 'No de reportes', tipo: 'texto', valor: null},
            ],
            [],
            false,
            false
         ).toJSON(),
         generCampos.darCampoCompletarTexto('',[
            'la persona consultada cuenta con ',
            generCampos.darCampoTexto('',null,null,false,false).toJSON(),
            'registros de tipo ',
            generCampos.darCampoDesplegable('',null,null,['RESIDENCIAL','LABORAL']).toJSON(),
            ', donde ',
            generCampos.darCampoTexto('',null,null,false,false).toJSON(),
            ' se encuentra ',
            generCampos.darCampoTexto('',null,null,false,false).toJSON(),
            '.'
         ],false).toJSON()
       ]
    },
    {
       titulo: 'CORREOS ELECTRÓNICOS',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['Correo Electrónico', 'No de Reportes', 'Primer Reporte', 'Último Reporte'],
             listaValores: [],
             formato: [
                {label: 'Correo Electrónico', tipo: 'texto', valor: null},
                {label: 'No de Reportes', tipo: 'texto', valor: null},
                {label: 'Primer Reporte', tipo: 'texto', valor: null},
                {label: 'Último Reporte', tipo: 'texto', valor: null}
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'CONSULTA PROPIEDADES',
       campos: [
          {
             label: 'SOPORTE CONSULTA',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          },
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['Dirección', 'No de Matricula', 'Referencia Catastral', 'Ciudad'],
             listaValores: [],
             formato: [
                {label: 'Dirección', tipo: 'texto', valor: null},
                {label: 'No de Matricula', tipo: 'texto', valor: null},
                {label: 'Referencia Catastral', tipo: 'texto', valor: null},
                {label: 'Ciudad', tipo: 'texto', valor: null},
             ],
             ignorar: false
          },
          {
             label: 'Observaciones y/o hallazgos relevantes',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'CONSULTA VEHÍCULOS',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['Clase', 'Línea', 'Marca', 'Modelo', 'Placa', 'Servicio'],
             formato: [
                {label: 'Clase', tipo: 'texto', valor: null},
                {label: 'Línea', tipo: 'texto', valor: null},
                {label: 'Marca', tipo: 'texto', valor: null},
                {label: 'Modelo', tipo: 'texto', valor: null},
                {label: 'Placa', tipo: 'texto', valor: null},
                {label: 'Servicio', tipo: 'texto', valor: null},
             ],
             listaValores: [],
             ignorar: false
          },
          {
             label: 'Observaciones /o hallazgos relevantes',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    }
   ]

export default(informeConsultas)